<template>
  <div>
    <van-tabs id="tabs" v-model="active">
      <van-tab title="未取"><OthersOrderList :order-status="'PAID'" /></van-tab>
      <van-tab title="全部订单"><OthersOrderList /></van-tab>
    </van-tabs>
    <div class="add-button">
      <div class="real-add-button" @click="navTo('/area')">存柜</div>
    </div>
  </div>
</template>

<script>
import OthersOrderList from "@/components/OthersOrderList";
export default {
  name: "OthersOrder",
  components: {OthersOrderList},
  data: function () {
    return {
      active: 0
    }
  }
}
</script>

<style scoped>
.add-button {
  position: fixed;
  right: 25px;
  bottom: 80px;
  z-index: 10000;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
.real-add-button {
  width: 50px;
  height: 50px;
  background: linear-gradient(-30deg, #6CCA11 0%, #A9F12B 100%);
  box-shadow: 0px 5px 10px 0px rgba(113, 225, 4, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 25px;
}
</style>
