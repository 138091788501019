<template>
  <div style="background-color: white; position: fixed; height: 100%; width: 100%">
    <div style="background: linear-gradient(-30deg, #FFBD04 0%, #FFD04D 100%);">
      <div style="display: flex;padding: 30px 15px">
        <van-image :src="courier.avatarUrl" width="65" height="65" round/>
        <div style="display: flex; flex-direction: column; margin: 0 15px; justify-content: space-between">
          <span class="courier-name">{{courier.nickName || '张三'}}</span>
          <span style="background-color: #EC9601; border-radius: 4px; color: white; padding: 4px; font-size: 12px; place-self: flex-start;">{{courier.shopName || '未绑定'}}</span>
        </div>
      </div>
    </div>
    <div style="border-radius: 15px;background-color: white; transform: translate(0, -12px); display: flex; flex-direction: column; padding: 18px 10px">
      <div style="display: flex;">
        <div class="info-item" style="display: flex; justify-content: space-between">
          <div style="display: flex; flex-direction: column">
            <span class="amount">{{ courier.todayCount[0] }}</span>
            <span class="amount-desc">今日配送量</span>
          </div>
          <van-image :src="require('@/assets/user/grzx_jrpsl_icon.png')" width="25" height="19"/>
        </div>
        <div class="info-item" style="display: flex; margin-left: 10px; justify-content: space-between">
          <div style="display: flex; flex-direction: column">
            <span class="amount">{{ courier.totalCount[0] }}</span>
            <span class="amount-desc">历史配送量</span>
          </div>
          <van-image :src="require('@/assets/user/grzx_lspsl_icon.png')" width="19" height="21"/>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div class="info-item" style="display: flex; justify-content: space-between">
          <div style="display: flex; flex-direction: column">
            <span class="amount">{{ (courier.wallet / 100).toFixed(2) }}</span>
            <span class="amount-desc">可用余额</span>
          </div>
          <van-image :src="require('@/assets/user/grzx_kyye_icon.png')" width="20" height="20"/>
        </div>
        <div class="info-item" style="display: flex; margin-left: 10px; justify-content: space-between">
          <div style="display: flex; flex-direction: column">
            <span class="amount">{{ courier.points / 100 }}</span>
            <span class="amount-desc">赠送余额</span>
          </div>
          <van-image :src="require('@/assets/user/grzx_zsye_icon.png')" width="21" height="19"/>
        </div>
      </div>
    </div>

    <van-cell title="消费明细" is-link @click="navTo('/consume')" center :border="false">
      <template #icon>
        <van-image :src="require('@/assets/user/grzx_xfmx_icon.png')" width="15" height="15" />
      </template>
    </van-cell>
    <div class="line" />
  </div>
</template>

<script>
import Client from "@/api";

export default {
  name: "CourierHome",
  data: function() {
    return {
      courier: {todayCount: [0], totalCount: [0], wallet: 0, points: 0, shopName: undefined}
    }
  },
  mounted() {
    this.loadInfo()
  },
  methods: {
    loadInfo() {
      Client.get('courier/info').then(res => {
        if (res.data.state === 'ok') {
          this.courier = res.data.data
        } else {
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        this.$toast.fail(err.response.data.msg)
      })
    }
  }
}
</script>

<style scoped>
.courier-name {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}
.info-item {
  flex-grow: 1;
  flex-basis: 50%;
  background: #FFFFFF;
  box-shadow: 0px 2px 15px 0px rgba(213, 213, 213, 0.4);
  border-radius: 10px;
  padding: 15px 20px 20px 20px;
}
.amount {
  font-size: 20px;
  font-family: Arial;
  font-weight: bold;
  color: #333333;
  line-height: 12px;
}
.amount-desc {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 15px;
  line-height: 12px;
}

/deep/ .van-cell__title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 19px;
  margin-left: 14px;
}
.line {
  margin: 10px 20px;
  /*background: #EFEFEF;*/
  height: 0;
  border: none;
  border-bottom: 1px solid #EFEFEF;
}
</style>
