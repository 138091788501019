<template>
  <div  style="background-color: #F5F5F5 !important;">
    <Home v-if="active === 0" />
    <OthersOrder v-if="active === 1" />
    <CourierHome v-if="active === 2" />
    <van-tabbar id="tabbar" v-model="active" safe-area-inset-bottom active-color="#FFBD04" inactive-color="#999999" >
      <van-tabbar-item>
        <span>商城订单</span>
        <template #icon="props">
          <img :src="props.active ? require('@/assets/tabbar/tab_scdd_icon_sel.png') : require('@/assets/tabbar/tab_scdd_icon_nor.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="orders-o">
        <span>其他订单</span>
        <template #icon="props">
          <img :src="props.active ? require('@/assets/tabbar/tab_qtdd_icon_sel.png') : require('@/assets/tabbar/tab_qtdd_icon_nor.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o">
        <span>个人中心</span>
        <template #icon="props">
          <img :src="props.active ? require('@/assets/tabbar/tab_grzx_icon_sel.png') : require('@/assets/tabbar/tab_grzx_icon_nor.png')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Home from "@/components/Home";
import OthersOrder from "@/components/OthersOrder";
import CourierHome from "@/components/CourierHome";
import Client from "@/api";
export default {
  name: "index",
  components: {CourierHome, OthersOrder, Home},
  data: function () {
    return {
      active: Number(sessionStorage.getItem('indexActive')) || 0
    }
  },
  watch: {
    active: function(val) {
      sessionStorage.setItem('indexActive', val.toString())
      this.handleTabSwitch(val)
    }
  },
  created() {
    let token = this.$route.query.token || ''
    if (token && token !== '') {
      localStorage.setItem('token', token)
      this.getInfo()
    }
  },
  mounted() {
    // localStorage.removeItem('token')
    localStorage.removeItem('orderId')
    document.title = '商城订单'
  },
  methods: {
    handleTabSwitch(tabIndex) {
      switch (tabIndex) {
        case 0:
          document.title = '商城订单'
          break
        case 1:
          document.title = '其他订单'
          break
        case 2:
          document.title = '个人中心'
          break
      }
    },
    getInfo() {
      Client.get('courier/info').then(res => {
        if (res.data.state === 'ok') {
          localStorage.setItem("username", res.data.data.userName)
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .van-tabs__wrap {
  padding-bottom: 12px;
}
/deep/ .van-tab {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 25px;
}
/deep/ .van-tab--active {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
}
/deep/ .van-tabs__line {
  width: 18px;
  height: 5px;
  background: linear-gradient(90deg, #FFBD04 0%, #FFD04D 100%);
  border-radius: 3px;
}
/deep/ .van-tabbar-item__text {
  font-size: 11px;
}
</style>
