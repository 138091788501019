<template>
  <van-pull-refresh class="refresh" v-model="refreshing" @refresh="onRefresh">
    <van-empty style="padding-bottom: 200px; height: 100%; position: absolute; width: 100%" :image="require('@/assets/wudingdan_img@2x.png')" description="暂无相关订单" v-show="courierOrderList.length === 0"/>
    <van-list
        v-show="courierOrderList.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(order, index) in courierOrderList" :key="index" style="margin-bottom: 10px">
        <template #title>
          <div class="item-title">
            <div style="display: flex; align-items: center"><van-image :src="require('@/assets/order/yonghu_icon.png')" width="17" height="17"/><span class="user-label">顾客{{order.mobileTail}}</span></div>
            <span class="user-label">{{order.areaName}}</span>
          </div>
        </template>
        <template #label>
          <div style="margin-top: 18px">
            <div style="background-color: #F5F5F5; border-radius: 4px; padding: 14px 10px; display: flex; flex-direction: column">
              <span class="order-info-label">取餐码：<span style="color: #FFBD04; font-weight: bold">{{order.takeCode.code}}</span></span>
              <span class="order-info-label" style="margin-top: 5px">保温柜：{{order.compartmentList.map(item => { return item.lockerName + item.compartmentName}).join(', ')}}</span>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
              <div style="color: #323233; font-size: 13px; margin-top: 6px; line-height: 28px">
                <div style="display: flex; align-items: center; margin-bottom: 10px"><van-image :src="require('@/assets/order/shijian_icon.png')" width="15" height="15"/><span class="order-detail-info">{{order.createTime.replace('T', ' ')}}</span></div>
                <div style="display: flex; align-items: center; margin-bottom: 10px"><van-image :src="require('@/assets/order/danhao_icon.png')" width="13" height="15"/><span class="order-detail-info">{{order.orderSn}}</span></div>
              </div>
              <div @click="sendSms(order)" style="border: 1px solid #FFBD04; padding: 4px 4px; color: #FFBD04; text-align: center; border-radius: 4px">点击复制短信<br>通知顾客</div>
              <!--<div v-if="order.orderStatus !== 'FINISH'" class="cabinet">
                <span>未取餐</span>
              </div>
              <div v-else class="no-cabinet">
                <span>已完成</span>
              </div>-->
            </div>
          </div>
        </template>
      </van-cell>

    </van-list>
  </van-pull-refresh>
</template>

<script>
import Client from "@/api";

export default {
  name: "OthersOrderList",
  props: {
    orderStatus: {
      type: String,
      default: undefined
    }
  },
  data: function () {
    return {
      courierOrderList: [],
      refreshing: false,
      loading: false,
      finished: false,

      pageNum: 1,
      pageSize: 10,
    }
  },
  mounted() {
    let tabbarHeight = document.getElementById('tabbar').clientHeight
    let tabsHeight = document.getElementById('tabs').clientHeight
    let refreshHeight = window.innerHeight - tabsHeight - tabbarHeight + 10 + 'px'
    let elements = document.getElementsByClassName('refresh')
    elements[elements.length - 1].style.height = refreshHeight

    if (!this.loading) this.onRefresh()
  },
  methods: {
    onRefresh() {
      this.pageNum = 1
      this.courierOrderList = []
      this.onLoad()
    },
    onLoad() {
      Client.get('/courierOrder', {params: {pageNum: this.pageNum++, pageSize: this.pageSize, orderStatus: this.orderStatus, orderType: 'CUSTOM'}}).then(res => {
        this.loading = false
        this.refreshing = false
        this.courierOrderList.push(...res.data.list)
        this.finished = res.data.list.length < this.pageSize
      }).catch(err => {
        this.loading = false
        this.finished = true
        this.$toast.fail(err.response.data.msg)
      })
    },
    orderStatusText(status) {
      switch (status) {
        case 'PAID': return '已支付'
        case 'UNPAID': return '未支付'
        case 'DONE': return '部分完成'
        case 'DELIVERED': return '已配送'
        case 'FINISH': return '已完成'
      }
    },
    sendSms(order) {
      console.log(order)
      let cellNoText = order.compartmentList.map(com => com.lockerName + com.compartmentName).join(',')
      let username = localStorage.getItem('username')
      let takeCode = order.takeCode.code
      let smsText = `【裕登小馋猫】您的餐品已存入${cellNoText}，取餐码${takeCode}一小时内有效，请您尽快取餐！送餐人${username}`
      console.log(smsText)
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({url: `/pages/clipboard/clipboard?text=${smsText}`})
    }
  }
}
</script>

<style scoped>


.item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.item-title::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 16px;
  top: 42px;
  z-index: 9999;
  left: 16px;
  border-bottom: 1px solid #EFEFEF;
}
.user-label {
  margin-left: 5px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
}
.order-detail-info {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  line-height: 25px;
}
.order-info-label {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
}
/deep/ .van-list {
  margin: 8px 10px;
}
/deep/ .van-cell {
  border-radius: 10px;
  /*margin-bottom: 10px;*/
}
.title {
  font-size: 13px;
}
.refresh {
  overflow: scroll
}
</style>
