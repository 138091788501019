<template>
  <div>
    <van-tabs id="tabs" v-model="active">
      <van-tab title="待配送"><UserOrderList :order-status="'PAID'" /></van-tab>
      <van-tab title="已配送"><UserOrderList :order-status="'DELIVERED'" /></van-tab>
      <van-tab title="全部订单"><UserOrderList /></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import UserOrderList from "@/components/UserOrderList";
export default {
  name: "Home",
  components: {UserOrderList},
  data: function () {
    return {
      active: 0
    }
  }
}
</script>

<style scoped>

</style>
