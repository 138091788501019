<template>
  <van-pull-refresh class="refresh" v-model="refreshing" @refresh="onRefresh">
    <van-empty style="padding-bottom: 200px; height: 100%; position: absolute; width: 100%" :image="require('@/assets/wudingdan_img@2x.png')" description="暂无相关订单" v-show="list.length === 0"/>
    <van-list
        v-show="list.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index" style="margin-bottom: 10px">
        <template #title>
          <div style="border-bottom: 1px solid #EFEFEF; padding-bottom: 13px">
            <div style="font-size: 12px; color: #333333; font-weight: bold">#<span style="font-size: 20px;">{{item.receiptNo | zeroFill}}</span></div>
            <div class="item-title">
              <div style="display: flex; align-items: center"><van-image :src="require('@/assets/order/yonghu_icon.png')" width="17" height="17"/><span class="user-label">{{item.consignee}}</span></div>
              <span class="user-label">{{item.areaName}}</span>
            </div>
<!--            <div style="height: 1px; background-color: #EFEFEF; margin-top: 15px" />-->
          </div>
        </template>
        <template #label>
          <div style="margin-top: 18px">
            <van-cell style="padding: 6px 0" :title-class="'title'" :border="false" @click="clickShowFood(item.id)">
              <template #title>
                <div style="display: flex; align-items: center"><van-image :src="require('@/assets/order/shangpin_icon.png')" width="15" height="15"/><span class="goods-amount-label">{{`商品(${item.orderFoodList.length})`}}</span></div>
              </template>
              <template #right-icon>
                <van-image :src="item.id === showFoodOrderId ? require('@/assets/order/shangla_icon.png') : require('@/assets/order/xiala_icon.png')" width="12" height="7" style="margin-top: 8px" />
              </template>
            </van-cell>
            <div v-show="item.id === showFoodOrderId" style="background-color: #F5F5F5; border-radius: 4px; padding: 14px 10px">
              <div style="display: flex; justify-content: space-between; align-items: center" v-for="(food, index) in item.orderFoodList" :key="index">
                <span class="food-name" v-if="food.skuAttrName && food.skuAttrName !== ''">{{food.foodName + '(' + food.skuAttrName + ')' }}</span>
                <span class="food-name" v-else>{{food.foodName }}</span>
                <span class="dot-line" style="height: 1px; flex-grow: 1; border-bottom: 1px dotted #C4C4C4; margin-left: 10px; margin-right: 10px"> </span>
                <span class="food-name">x{{food.amount}}</span>
              </div>
<!--              <van-cell style="padding: 2px 6px" v-show="item.id === showFoodOrderId" :title-class="'food-title'" v-for="food in item.orderFoodList" :key="food.id" :title="food.foodName" :value="food.price / 100.0 + '元'" :border="false" />-->
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
              <div style="color: #323233; font-size: 13px; margin-top: 6px; line-height: 28px">
                <div style="display: flex; align-items: center; margin-bottom: 10px"><van-image :src="require('@/assets/order/shijian_icon.png')" width="15" height="15"/><span class="order-detail-info">{{item.createTime.replace('T', ' ')}}</span></div>
                <div style="display: flex; align-items: center; margin-bottom: 10px"><van-image :src="require('@/assets/order/danhao_icon.png')" width="13" height="15"/><span class="order-detail-info">{{item.orderSn}}</span></div>
                <div style="display: flex; align-items: center; margin-bottom: 10px" @click="makeCall(item.mobileNumber)"><van-image :src="require('@/assets/order/dianhua_icon.png')" width="13" height="15"/><span class="order-detail-info" style="color:#2394FD;">{{item.mobileNumber}}</span></div>
              </div>
              <div v-if="['PAID', 'REQUEST', 'REJECT'].includes(item.orderStatus)" @click="onClickPickLocker(item)" class="cabinet">
                <span>存柜</span>
              </div>
              <div v-else-if="orderDeliveryStatus.indexOf(item.orderStatus) !== -1" class="no-cabinet">
                <span>已配送</span>
              </div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import Client from "@/api"

export default {
  name: "UserOrderList",
  props: {
    orderStatus: { type: String, default: null }
  },
  data: function () {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],

      pageNum: 1,
      pageSize: 10,

      showFoodOrderId: null,

      orderDeliveryStatus: ['PARTLOCKED','ALLLOCKED','DELIVERED','FINISH','EXPIRED']
    }
  },
  mounted() {
    this.$nextTick(() => {
      let tabbarHeight = document.getElementById('tabbar').clientHeight
      // console.log(`tabbarHeight=${tabbarHeight}`)
      let tabsHeight = document.getElementById('tabs').clientHeight
      // console.log(`tabsHeight=${tabsHeight}`)
      let refreshHeight = window.innerHeight - tabsHeight - tabbarHeight + 10 + 'px'
      let elements = document.getElementsByClassName('refresh')
      elements[elements.length - 1].style.height = refreshHeight
      // console.log(`refreshHeight=${refreshHeight}`)
    })
    if (!this.loading) {
      this.onRefresh()
    }
    // console.log('mounted()' + this.orderStatus)
  },
  methods: {
    onRefresh() {
      this.pageNum = 1
      this.list = []
      this.onLoad()
    },
    makeCall(number) {
      window.location.href = 'tel:' + number
      // eslint-disable-next-line no-undef
      // wx.miniProgram.makePhoneCall({
      //   phoneNumber: number,
      // })
    },
    onLoad() {
      Client.get('courier/userOrder', {
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum++,
          orderStatus: this.orderStatus
        }
      }).then(res => {
        this.loading = false
        this.refreshing = false
        this.list.push(...res.data.list)
        this.finished = res.data.list.length < this.pageSize
      }).catch(err => {
        this.loading = false
        this.finished = true
        if (err.response.status === 403) {
          localStorage.removeItem('token')
          this.navTo('/entry')
        } else {
          this.$toast.fail(err.response.data.msg)
        }
      })
    },
    clickShowFood(orderId) {
      if (this.showFoodOrderId === orderId) {
        this.showFoodOrderId = null
      } else {
        this.showFoodOrderId = orderId
      }
    },
    onClickPickLocker(order) {
      localStorage.setItem('orderId', order.id)
      localStorage.setItem('userAreaId', order.areaId)
      this.navTo('/locker')
      // this.$router.push({path: '/locker', query: {orderId: order.id, userAreaId: order.areaId}})
    },
    orderStatusText(status) {
      switch (status) {
        case 'PAID': return '已支付'
        case 'UNPAID': return '未支付'
        case 'REQUEST': return '请求取消'
        case 'REJECT': return '驳回取消'
        case 'CANCELED': return '已取消'
        case 'DELIVERED': return '已配送'
        case 'FINISH': return '已完成'
      }
    }
  }
}
</script>

<style scoped>
/deep/ .van-list {
  margin: 8px 10px;
}
/deep/ .van-cell {
  border-radius: 10px;
  /*margin-bottom: 10px;*/
}
.item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.user-label {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: 5px;
  line-height: 25px;
}

.goods-amount-label {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 10px;
  line-height: 25px;
}
.food-name {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 25px;
}
.order-detail-info {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  line-height: 25px;
}
.title {
  font-size: 13px;
}
.food-title {
  font-size: 13px;
  color: grey;
}
.cabinet {
  width: 50px;
  height: 50px;
  background: linear-gradient(-30deg, #FFBD04 0%, #FFD04D 100%);
  box-shadow: 0px 5px 10px 0px rgba(225, 166, 4, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cabinet span {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 25px;
}
.cabinet:active {
  background-color: #ebedf0;
  border-radius: 50%;
}
.no-cabinet{
  width: 50px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-cabinet span {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 25px;
}
.refresh {
  overflow: scroll;
}
</style>
